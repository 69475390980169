import { useTranslation } from "react-i18next";

export default function ServiceItem({item}) {
  const { i18n } = useTranslation();
  const { title, desc, icon } = item
  return (
    <div className="service-item padd-15">
      <div className="service-item-inner">
        <div className="icon">
          <i className={`fa fa-${icon}`}></i>
        </div>
        <h4>{title[i18n.language]}</h4>
        <p>{desc[i18n.language]}</p>
      </div>
    </div>
  );
}
