import { useTranslation } from "react-i18next";

export default function TimelineItem({ item }) {
  const { i18n } = useTranslation()
  const { date, title,desc,place } = item
  return (
    <div className="timeline-item">
      <div className="circle-dot"></div>
      <h3 className="timeline-date">
        <i className="fa fa-calendar"></i> {date}
      </h3>
      <h4 className="timeline-title">
        {title[i18n.language]}
      </h4>
      <p className="timeline-text">{desc[i18n.language]}</p>
      <p className="timeline-school">{place}</p>
    </div>
  );
}
