const en = {
    translation: {
        "app_name": "Manuel Njiakim",
        "app_title": "Manuel Njiakim - FullStack & Mobile Application Developer",
        "Theme Colors": "Theme Colors",
        "Home": "Home",
        "years": "years",
        "About": "About",
        "Services": "Services",
        "Portfolio": "Portfolio",
        "Contact": "Contact",
        "Hello, my name is": "Hello, my name is",
        "I'm a": "I'm a",
        "FullStack Developer": "FullStack Developer",
        "home_desc": "I am a FullStack developer with a richtful experience of over 5 years. I use Javascript and PHP languages as well as a plethora of libraries and frameworks to develop effective and efficient web & mobile applications.",
        "Hire Me": "Hire Me",
        "I'm Manuel Njiakim and": "I'm Manuel Njiakim a",
        "Web Developer": "Web Developer",
        "about_desc": "Evolving as a web developer for more than 5 years, I have developed an expertise in PHP (Symfony & Laravel) & Javascript(React Js, Next.Js). During my career, I had to participate in several web and software development projects for several institutions. I had the opportunity to work in various environments during which I participated in the collection of needs, analysis, design and development of web and mobile applications.",
        "Download CV": "Download CV",
        "Email": "Email",
        "Phone": "Phone",
        "Address": "Address",
        "Website": "Website",
        "About Me": "About Me",
        "Education": "Education",
        "Experience": "Experience",
        "My Last Projects": "My Last Projects",
        "Contact Me": "Contact Me",
        "Have You Any Question ?": "Have You Any Question ?",
        "I'M AT YOUR SERVICE": "I'M AT YOUR SERVICE",
        "SEND ME AN EMAIL": "SEND ME AN EMAIL",
        "I'M VERY RESPONSIVE TO MESSAGES": "I'M VERY RESPONSIVE TO MESSAGES",
        "Meet Me In": "Meet Me In",
        "Call Me On": "Call Me On",
        "Send Message": "Send Message",
        "Name": "Name",
        "Subject": "Subject",
        "Message": "Message",
        "Message Sent!" : "Message Sent!",
        "An error occurred, Try again!" : "An error occurred, Try again!",
    }
}

export default en
