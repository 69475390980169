
const fr = {
    translation: {
    "app_name": "Manuel Njiakim",
    "app_title": "Manuel Njiakim - Développeur FullStack & Mobiles",
    "Theme Colors": "Couleurs Thème",
    "years": "ans",
    "Home": "Accueil",
    "About": "À Propos",
    "Services": "Services",
    "Portfolio": "Portfolio",
    "Contact": "Contact",
    "Hello, my name is": "Salut, mon nom est",
    "I'm a": "Je suis",
    "FullStack Developer": "Développeur FullStack",
    "home_desc": "Je suis un développeur FullStack PHP/Javascript Sénior avec une riche expérience de plus de 5 ans. J'utilise les languages Javascript et PHP ainsi qu'une pléthore et librairies et Frameworks qui en découlent pour développer des applications web & mobile efficaces et efficientes.",
    "Hire Me": "Recrutez-Moi",
    "I'm Manuel Njiakim and": "Je suis Manuel Njiakim un",
    "Web Developer": "Développeur Web",
    "about_desc": "Evoluant en tant que développeur web depuis plus de 5 ans, j'ai développé une expertise en PHP (Symfony, Laravel) & Javascript(React Js, Next.Js). Au cours de ma carrière, j'ai eu à participer à plusieurs projets de développement web et logiciel pour plusieurs institutions. J'ai eu l'occasion de travailler dans des environnements variés au cours desquels j'ai participé au recueil des besoins, à l'analyse, à la conception et au développement d'applications web et mobiles.",
    "Download CV": "Télécharger CV",
    "Email": "Email",
    "Phone": "Téléphone",
    "Address": "Adresse",
    "Website": "Site web",
    "About Me": "À Propos De Moi",
    "Education": "Formation",
    "Experience": "Experience",
    "My Last Projects": "Mes Récents Projets",
    "Contact Me": "Contactez-Moi",
    "Have You Any Question ?": "Avez Vous Des Questions ?",
    "I'M AT YOUR SERVICE": "JE SUIS À VOTRE SERVICE",
    "SEND ME AN EMAIL": "ENVOYER UN EMAIL",
    "I'M VERY RESPONSIVE TO MESSAGES": "JE SUIS TRÈS RÉACTIF AUX MESSAGES",
    "Meet Me In": "Rencontrez-Moi",
    "Call Me On": "Appelez-Moi",
    "Send Message": "Envoyer un Message",
    "Name": "Nom",
    "Subject": "Objet",
    "Message": "Message",
    "Message Sent!" : "Message Envoyé!",
    "An error occurred, Try again!" : "Une Erreur est survenue. Réessayez!",
}}
export default fr
