export default function PortfolioItem({ item }) {
  const { title, src, link } = item;
  return (
    <div className="portfolio-item padd-15">
      <div className="portfolio-item-inner shadow-dark">
        <div className="portfolio-img">
          <a href={link} title={title} target="_blank" rel="noreferrer"><img alt={title} src={src} /></a>
        </div>
      </div>
    </div>
  );
}
