import React from 'react'
import { useTranslation } from "react-i18next";
import infos from '../../data/info.json'
import skills from '../../data/skills.json'
import educations from '../../data/educations.json'
import experiences from '../../data/experiences.json'
import InfoItem from './Components/InfoItem';
import SkillItem from './Components/SkillItem';
import TimelineItem from './Components/TimelineItem';

function About(props) {
  const { t } = useTranslation();

    return(
      <section className="section about">
        <div className="container">
          <div className="row">
            <div className="section-title padd-15">
              <h2>{t('About Me')}</h2>
            </div>
          </div>
          <div className="row">
            <div className="about-content padd-15">
              <div className="row">
                <div className="about-text padd-15">
                  <h3>{t("I'm Manuel Njiakim and")} <span>{t("Fullstack Developer")}</span></h3>
                  <p>{t('about_desc')}</p>
                </div>
              </div>
              <div className="row">
                <div className="personal-info padd-15">
                  <div className="row">
                    {
                      infos.map(info => <InfoItem key={info.name} name={info.name} value={info.value} />)
                    }
                  </div>
                  <div className="row">
                    <div className="buttons padd-15">
                      <a href="/files/cv-manuel-njiakim-fullstack-developer.pdf" target="_blank" className="btn">{t('Download CV')}</a>
                    </div>
                  </div>
                </div>
                <div className="skills padd-15">
                  <div className="row">
                  {
                    skills.map(skill => <SkillItem key={skill.name} name={skill.name} percent={skill.percent} time={skill.time} />)
                  }
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="education padd-15">
                  <h3 className="title">{t('Education')}</h3>
                  <div className="row">
                    <div className="timeline-box padd-15">
                      <div className="timeline shadow-dark">
                        {
                          educations.map(education => <TimelineItem key={education.date+education.place} item={education} />)
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="experience padd-15">
                  <h3 className="title">{t('Experience')}</h3>
                  <div className="row">
                    <div className="timeline-box padd-15">
                      <div className="timeline shadow-dark">
                        {
                          experiences.map(experience => <TimelineItem key={experience.date+experience.place} item={experience} />)
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default About