import React, {useState} from 'react';
import { HashRouter as Router, Route, Routes  } from 'react-router-dom';
import SideBar from './Components/SideBar';
import ThemeSwitcher from './Components/ThemeSwitcher';
import Home from './layouts/Home'
import About from './layouts/About'
import Services from './layouts/Services'
import Portfolio from './layouts/Portfolio'
import Contact from './layouts/Contact'
import ThemeContext from './utils/contexts/ThemeContext'

function App() {

  const [mode, setMode] = useState(localStorage.getItem('theme-mode'))
  const toggleMode = () => {
      localStorage.setItem("theme-mode",mode === 'light' ? 'dark' : 'light')
      setMode(mode === 'light' ? 'dark' : 'light')
  }

  return (
    <ThemeContext.Provider value={{mode, toggleMode}}>
      <div className="main-container">
        <Router>
          <SideBar />
          <div className="main-content">
                <Routes>
                  <Route exact path="/" element={<Home />}/>
                  <Route path="/about" element={<About />}/>
                  <Route path="/services" element={<Services />}/>
                  <Route path="/portfolio" element={<Portfolio />}/>
                  <Route path="/contact" element={<Contact />}/> 
                </Routes>
          </div>
        </Router>
      </div>
      <ThemeSwitcher />
    </ThemeContext.Provider>
  );
}

export default App;
