import React, {useRef, useContext} from 'react'
import { useTranslation } from "react-i18next";
import emailjs from 'emailjs-com';
import infos from '../../data/info.json'
import InfoItem from './Components/InfoItem';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ThemeContext from '../../utils/contexts/ThemeContext'

function Contact(props) {
  const { t } = useTranslation();
  const { mode } = useContext(ThemeContext)

  const contactForm = useRef(null)

  function sendMessage(evt) {
    evt.preventDefault();

    emailjs.sendForm('service_wnnojk9', 'template_7rzeqt8', evt.target,'yoMDqQgLK1UvXX0RO')
      .then((result) => {
          console.log(result.text);
          toast.success(t('Message Sent!'), {
            type: 'success'
            });
          contactForm.current?.reset()
      }, (error) => {
          console.log(error.text);
          toast.error(t('An error occurred, Try again!'), {
            type: 'error'
            });
          contactForm.current?.reset()
      });
  }

    return(
      <section className="section contact">
            <div className="container">
              <div className="row">
                <div className="section-title padd-15">
                  <h2>{t('Contact Me')}</h2>
                </div>
              </div>
              <h3 className="contact-title padd-15">{t('Have You Any Question ?')}</h3>
              <h4 className="contact-sub-title padd-15">{t("I'M AT YOUR SERVICE")}</h4>
              <div className="row">
              {
                infos.map(info => <InfoItem key={info.name} title={info.title} value={info.value} icon={info.icon} />)
              }
              </div>
              <h3 className="contact-title padd-15">{t('SEND ME AN EMAIL')}</h3>
              <h4 className="contact-sub-title padd-15">{t("I'M VERY RESPONSIVE TO MESSAGES")}</h4>
              <div className="row">
                <form ref={contactForm} onSubmit={sendMessage}>
                  <div className="contact-form padd-15">
                    <div className="row">
                        <div className="form-item col-6 padd-15">
                            <div className="form-group">
                                <input required type="text" name='name' className="form-control" placeholder={t("Name")} />
                            </div>
                        </div>
                        <div className="form-item col-6 padd-15">
                            <div className="form-group">
                                <input required type="email" name='email' className="form-control" placeholder={t("Email")} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-item col-12 padd-15">
                            <div className="form-group">
                                <input required type="text" name='subject' className="form-control" placeholder={t("Subject")} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-item col-12 padd-15">
                            <div className="form-group">
                                <textarea required type="text" name='message' className="form-control" placeholder={t("Message")}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-item col-12 padd-15">
                            <div className="form-group">
                                <button type="submit" className="btn">{t('Send Message')}</button>
                            </div>
                        </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              theme={mode}
              pauseOnHover
            />
        </section>
    )
}

export default Contact