import React, {useEffect, useState, useContext} from "react";
import { useTranslation } from "react-i18next";
import ThemeContext from '../../utils/contexts/ThemeContext'

function ThemeSwitcher(props) {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false)
  // const [mode, setMode] = useState(localStorage.getItem("theme-mode")==='dark'?'dark':'light')
  const { mode, toggleMode } = useContext(ThemeContext)


  function toggleNav() {
    setOpen(!isOpen)
  }
  
  const closeNav = () => {
    setOpen(false)
  };

  useEffect(() => {
    if(isOpen){
      window.addEventListener("scroll", closeNav);
      document.querySelector(".section").addEventListener("click", closeNav);
    }
    else{
      window.removeEventListener("scroll", closeNav);
      document.querySelector(".section").removeEventListener("click", closeNav);
    }
  },[isOpen])

  useEffect(() => {
    // Theme Mode
    if(localStorage.getItem("theme-mode") === 'dark' && !document.body.classList.contains("dark"))
        document.body.classList.toggle("dark")
  },[])

  function setActiveColor(color) {
    const alternateStyles = document.querySelectorAll(".alternate-style");
    alternateStyles.forEach((style) => {
      if (color === style.getAttribute("title"))
        style.removeAttribute("disabled");
      else style.setAttribute("disabled", "true");
    });
  }

  function toggleMode2 () {
    toggleMode()
    document.body.classList.toggle("dark")
  }

  return (
    <div className={`style-switcher ${isOpen?'open':''}`}>
      <div className="style-switcher-toogler s-icon" onClick={toggleNav}>
        <i className="fas fa-cog fa-spin"></i>
      </div>
      <div className="day-night s-icon" onClick={toggleMode2}>
        <i className={`fas fa-${mode==='dark'?'sun':'moon'}`}></i>
      </div>
      <h4>{t("Theme Colors")}</h4>
      <div className="colors">
        <span className="color-1" onClick={evt => setActiveColor("color-1")}></span>
        <span className="color-2" onClick={evt => setActiveColor("color-2")}></span>
        <span className="color-3" onClick={evt => setActiveColor("color-3")}></span>
        <span className="color-4" onClick={evt => setActiveColor("color-4")}></span>
        <span className="color-5" onClick={evt => setActiveColor("color-5")}></span>
      </div>
    </div>
  );
}

export default ThemeSwitcher
