import React from 'react'
import { useTranslation } from "react-i18next";

export default function InfoItem({name, percent, time}) {
  const { t } = useTranslation();

    return(
        <div className="skill-item padd-15">
            <h5>{name}</h5>
            <div className="progress">
                <div className="progress-in" style={{width: `${percent}%`}}></div>
                <div className="skill-percent">+{time} {t('years')}</div>
            </div>
        </div>
    )
}