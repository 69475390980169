import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link,NavLink } from "react-router-dom";

function SideBar(props) {
  const { t, i18n } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  function toggleNav() {
    setOpen(!isOpen);
  }

  function toggleLang() {
    i18n.changeLanguage(i18n.language === "fr" ? "en" : "fr");
  }

  function closeNav() {
    setOpen(false);
  }

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("scroll", closeNav);
      document.querySelector(".section").addEventListener("click", closeNav);
    } else {
      window.removeEventListener("scroll", closeNav);
      document.querySelector(".section").removeEventListener("click", closeNav);
    }
  }, [isOpen]);
  return (
    <div className={`aside ${isOpen ? "a-open" : ""}`}>
      <div className="logo">
        <Link to="/">
          <span>M</span>anuel
        </Link>
      </div>
      <div
        className={`nav-toggler ${isOpen ? "btna-open" : ""}`}
        onClick={toggleNav}
      >
        <span></span>
      </div>
      <ul className="nav">
        <li>
          <NavLink to="/" onClick={closeNav}>
            {({ isActive }) => (
              <span className={isActive ? "active" : undefined}>
                <i className="fa fa-home"></i>
                {t("Home")}
              </span>
            )}
          </NavLink>
        </li>
        <li>
          <NavLink to="about" onClick={closeNav}>
            {({ isActive }) => (
              <span className={isActive ? "active" : undefined}>
                <i className="fa fa-user"></i>
                {t("About")}
              </span>
            )}
          </NavLink>
        </li>
        <li>
          <NavLink to="services" onClick={closeNav}>
            {({ isActive }) => (
              <span className={isActive ? "active" : undefined}>
                <i className="fa fa-list"></i>
                {t("Services")}
              </span>
            )}
          </NavLink>
        </li>
        <li>
          <NavLink to="portfolio" onClick={closeNav}>
            {({ isActive }) => (
              <span className={isActive ? "active" : undefined}>
                <i className="fa fa-briefcase"></i>
                {t("Portfolio")}
              </span>
            )}
          </NavLink>
        </li>
        <li>
          <NavLink to="contact" onClick={closeNav}>
            {({ isActive }) => (
              <span className={isActive ? "active" : undefined}>
                <i className="fa fa-comments"></i>
                {t("Contact")}
              </span>
            )}
          </NavLink>
        </li>
      </ul>
      <div className="lang-toggler">
        <span onClick={toggleLang}>
          {i18n.language === "fr" ? "English" : "Français"}
        </span>
      </div>
    </div>
  );
}

export default SideBar;
