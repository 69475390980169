import React from 'react'
import { useTranslation } from "react-i18next";
import portfolio from '../../data/portfolio.json'
import PortfolioItem from './Components/PortfolioItem';
function Portfolio(props) {
  const { t } = useTranslation();

    return(
      <section className="section portfolio">
            <div className="container">
              <div className="row">
                <div className="section-title padd-15">
                  <h2>{t('Portfolio')}</h2>
                </div>
              </div>
              <div className="row">
                  <div className="portfolio-heading padd-15">
                    <h2>{t('My Last Projects')}</h2>
                  </div>
              </div>
              <div className="row">
              {
                portfolio.map(porto => <PortfolioItem key={porto.link} item={porto} />)
              }
              </div>
            </div>
        </section>
    )
}

export default Portfolio