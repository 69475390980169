import React from 'react'
import { useTranslation } from "react-i18next";
import services from '../../data/services.json'
import ServiceItem from './Components/ServiceItem';
function Services(props) {
  const { t } = useTranslation();

    return(
      <section className="section service">
          <div className="container">
            <div className="row">
              <div className="section-title padd-15">
                <h2>{t('Services')}</h2>
              </div>
            </div>
            <div className="row">
              {
                services.map(service => <ServiceItem key={service.icon} item={service} />)
              }
            </div>
          </div>
        </section>
    )
}

export default Services