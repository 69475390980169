import React from 'react'
import { useTranslation } from "react-i18next";
import {Link} from 'react-router-dom'
function Home(props) {
  const { t } = useTranslation();

    return(
        <section className="home section">
          <div className="container">
            <div className="row">
              <div className="home-info padd-15">
                <h3 className="hello">
                  {t('Hello, my name is')} <span className="name">Manuel Njiakim</span>
                </h3>
                <h3 className="my-profession typewriter">
                  {t("I'm a")} <span className="typing">{t('FullStack Developer')}</span>
                </h3>
                <p>{t('home_desc')}
                </p>
                <Link to="contact" className="btn hire-me">{t('Hire Me')}</Link>
              </div>
              <div className="home-img padd-15">
                <img src="images/Profile/Manuel Njiakim.webp" alt={t('app_title')} width="296" height="400" />
              </div>
            </div>
          </div>
        </section>
    )
}

export default Home