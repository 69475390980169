import { useTranslation } from "react-i18next";

export default function InfoItem({title, value, icon}) {
    const {t} = useTranslation();
    return(
        <div className="contact-info-item padd-15">
          <div className="icon"><i className={`fa ${icon}`}></i></div>
          <h4>{t(title)}</h4>
          <p>{value}</p>
        </div>
    )
}